var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-page-form__block"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name,
    },attrs:{"label":"Имя","placeholder":"Ваше имя","field":_vm.name,"name":"name","autocomplete":"name","error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введите текст на русском языке'
        : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
    },attrs:{"label":"Email*","placeholder":"example@yandex.ru","field":_vm.email,"type":"email","error":(!_vm.$v.email.required && _vm.$v.email.$error
        ? 'Обязательное поле'
        : !_vm.$v.email.email && _vm.$v.email.$error
        ? 'Некорректный e-mail'
        : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('div',{staticClass:"form-page-form__checkboxes"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox3) || _vm.errorMessage.checkbox3,
      },attrs:{"label":`Подтверждаю, что мне больше 13 лет`,"field":_vm.checkbox3,"error":_vm.errorMessage.checkbox3},on:{"input":function($event){_vm.checkbox3 = $event}}}),_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
      },attrs:{"label":`Соглашаюсь с <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>Правилами участия</a> и <a href='${_vm.publicPath}docs/agreement.docx' class='_undeline-link _bold' target='_blank'> обработкой персональных данных</a>`,"field":_vm.checkbox,"error":_vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox2) || _vm.errorMessage.checkbox2,
      },attrs:{"label":`Согласен получать рекламные сообщения от Kinder<sup>®</sup>`,"field":_vm.checkbox2,"error":_vm.errorMessage.checkbox2},on:{"input":function($event){_vm.checkbox2 = $event}}})],1),_c('div',{staticClass:"form-page-form__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Зарегистрироваться"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"form-page__another-option"},[_c('p',[_vm._v(" Вы уже зарегистрированы? "),_c('router-link',{staticClass:"_undeline-link",attrs:{"to":{ name: 'Login' }}},[_vm._v("Войти")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }