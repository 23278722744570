<template>
  <div class="table_holder w-100">
    <div class="table--desktop table">
      <div class="table__header">
        <div class="table__header-row"></div>
      </div>
      <div class="table__inner">
        <table>
          <thead>
            <tr>
              <th v-for="(item, index) in thead" :key="index">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table--mobile table">
      <div class="table__inner">
        <slot name="mobile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thead"],
};
</script>

<style lang="scss">
.table_holder {
  overflow: auto;

  td.status-bad {
    color: #912982;
  }
  td.status-moderation {
    color: #fff;
  }
  .error_icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: rem(17px);
    height: rem(17px);
    font-size: rem(12px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(1, 63, 93, 0.15);
    border-radius: 50%;
  }
}
.table {
  width: 100%;
  margin: 0 auto;
  text-transform: uppercase;
  &__inner {
    max-height: rem(330px);
    padding-right: rem(10px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: rem(5px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: $brown;
      border-radius: 13px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $red;
      transition: all 0.3s ease-in-out;
    }
  }
  table {
    width: 100%;
  }

  th {
    padding: 0 rem(30px) rem(17px);
    font-size: rem(16px);
    line-height: 1;
    text-align: left;
    color: #fff;
  }

  &--desktop {
    display: none !important;
    max-width: 65%;
    margin-left: 0;
    td {
      font-size: rem(16px);
      padding: rem(16px) rem(30px);
    }
    tr {
      &:nth-child(odd) {
        td {
          position: relative;
          color: #3c140a;
          &::before {
            content: "";
            display: block;
            width: 120%;
            height: 100%;
            position: absolute;
            top: 0;
            background: #fff;
            transform: skew(-20deg);
            z-index: -1;
          }
          &:first-of-type {
            &::before {
              left: rem(10px);
            }
          }
          &:last-of-type {
            &::before {
              right: rem(10px);
            }
          }
        }
      }
    }
  }
  &--mobile {
    display: block;
    width: 100%;
    font-size: rem(16px);
    font-weight: 700;
    .table__inner {
      &::-webkit-scrollbar {
        width: rem(5px);
        opacity: 1;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(255, 227, 202, 0.5);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $red;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
      }
    }
    & > tr {
    }
    .table-mobile__inner {
      width: 100%;
      margin-bottom: rem(24px);
      tr {
        vertical-align: middle;
        &:nth-child(odd) {
          td {
            position: relative;
            color: #3c140a;
            &::before {
              content: "";
              display: block;
              width: 120%;
              height: 100%;
              position: absolute;
              top: 0;
              background: #fff;
              transform: skew(-10deg);
              z-index: -1;
            }
            &:first-of-type {
              &::before {
                left: rem(10px);
              }
            }
            &:last-of-type {
              &::before {
                right: rem(10px);
              }
            }
          }
        }
        td {
          text-transform: uppercase;
          font-size: rem(13px);
          padding: rem(7px) rem(10px) rem(7px) rem(20px);
          &:first-of-type {
            font-family: "NuberNext";
          }
        }
      }
    }
  }
  @media (min-width: $sm) {
    &--desktop {
      display: block !important;
    }
    &--mobile {
      display: none !important;
    }
    &__inner {
      max-height: rem(435px);
      padding-right: rem(33px);
      &::-webkit-scrollbar {
        width: rem(8px);
      }
    }
  }
}
</style>
