<template>
  <section class="steps section">
    <div class="container">
      <div class="steps__title section-title">3 простых шага</div>
    </div>
    <svg
      class="steps__path hidden-xs"
      width="1284"
      height="324"
      viewBox="0 0 1284 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="path"
        d="M2.91797 121.587C2.91797 121.587 60.0769 3.00002 162.669 3C275.808 2.99998 292.864 104.366 336.072 215.64C406.073 395.913 593.751 287.586 636.277 223.649C688.813 144.665 715.238 45.3996 841.627 53.4212C968.016 61.4428 1080.21 332.864 1280.92 320.596"
        stroke="#52245D"
        stroke-width="3"
        stroke-linecap="square"
      />
      <path
        d="M2.91797 121.587C2.91797 121.587 60.0769 3.00002 162.669 3C275.808 2.99998 292.864 104.366 336.072 215.64C406.073 395.913 593.751 287.586 636.277 223.649C688.813 144.665 715.238 45.3996 841.627 53.4212C968.016 61.4428 1080.21 332.864 1280.92 320.596"
        stroke="white"
        stroke-width="5"
        stroke-linecap="square"
        stroke-dasharray="22 10"
      />
    </svg>
    <svg
      class="steps__path visible-xs"
      width="698"
      height="818"
      viewBox="0 0 698 818"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="path"
        d="M203.577 89.2592C235.885 117.895 359.236 186.179 466.436 231.702C539.931 262.911 562.93 346.369 413.572 403.516C329.133 435.823 411.45 489.325 352.835 517.927C196.831 594.052 260.091 773.645 359.268 799.377"
        stroke="#52245D"
        stroke-width="2.49282"
        stroke-linecap="round"
        stroke-dasharray="1.66 16.62"
      />
      <path
        d="M203.577 89.2592C235.885 117.895 359.236 186.179 466.436 231.702C539.931 262.911 562.93 346.369 413.572 403.516C329.133 435.823 411.45 489.325 352.835 517.927C196.831 594.052 260.091 773.645 359.268 799.377"
        stroke="white"
        stroke-width="5.49"
        stroke-linecap="round"
        stroke-dasharray="17 11"
      />
    </svg>

    <div class="steps__content">
      <img
        src="../assets/images/steps/doodle.svg"
        alt=""
        class="steps__item-decor"
      />
      <img
        src="../assets/images/steps/doodle-m.svg"
        alt=""
        class="steps__item-decor -mobile"
      />
      <div class="container">
        <div class="steps__list">
          <div class="steps__item">
            <img
              src="../assets/images/steps/bbl-1.svg"
              alt=""
              class="steps__item-bg"
            />
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/book.svg"
                alt=""
                class="steps__item-img"
              />
              <div class="steps__item-text">
                <div class="steps__item-index">1</div>
                <span>Читайте лесные <br />истории</span>
              </div>
            </div>
          </div>
          <div class="steps__item">
            <img
              src="../assets/images/steps/bbl-2.svg"
              alt=""
              class="steps__item-bg"
            />
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/hand.svg"
                alt=""
                class="steps__item-img"
              />
              <div class="steps__item-text">
                <div class="steps__item-index">2</div>
                <span>Регистрируйтесь <br />на сайте</span>
                <router-link
                  v-if="!$store.getters.user"
                  :to="{ name: 'Signup' }"
                  class="btn btn--bordered hidden-xs"
                >
                  <span
                    @click="
                      gtmEvent('', 'main', 'registration_start', '3_easy_steps')
                    "
                    >Регистрация</span
                  >
                </router-link>
              </div>
            </div>
          </div>
          <div class="steps__item">
            <img
              src="../assets/images/steps/bbl-3.svg"
              alt=""
              class="steps__item-bg"
            />
            <div class="steps__item-content">
              <img
                src="../assets/images/steps/postcard.svg"
                alt=""
                class="steps__item-img"
              />
              <div class="steps__item-text">
                <div class="steps__item-index">3</div>
                <span>Получайте открытку <br />и стикеры</span>
              </div>
            </div>
          </div>
        </div>
        <router-link
          v-if="!$store.getters.user"
          :to="{ name: 'Signup' }"
          class="btn btn--bordered visible-xs"
          >Регистрация</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 3,
        centerPadding: "0%",
        infinite: true,
        centerMode: true,
        fade: false,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        pauseOnHover: false,
      },
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  // align-items: center;
  padding-top: rem(160px) !important;
  // max-height: 100vh !important;
  background: #fff;
  overflow: hidden;
  z-index: 2;

  .container {
    z-index: 99;
  }
  &__title.section-title {
    text-align: center;
    margin-bottom: rem(40px);
  }
  &__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(90px);
  }
  .btn {
    margin: auto;
  }
  &__item {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    margin-right: rem(15px);
    &-decor {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 100;

      &.-mobile {
        display: none;
      }

      @media (orientation: portrait) {
        display: none;

        &.-mobile {
          display: block;
          width: 80%;
        }
      }
    }
    &-bg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
    }
    &-index {
      display: inline-block;
      vertical-align: middle;
      @include size(rem(23px));
      margin-right: rem(15px);
      margin-left: rem(-25px);
      margin-top: rem(-4px);
      border: 1px solid currentColor;
      border-radius: 50%;
      font-size: rem(15px);
      line-height: rem(23px);
    }
    .btn {
      margin: auto;
    }
    &:not(:last-of-type) {
      margin-bottom: rem(75px);
    }
    &:nth-child(1) {
      .steps__item-content {
      }
      .steps__item-bg {
        top: 40%;
      }
      .steps__item-img {
        width: rem(160px);
      }
      .steps__item-text {
      }
    }
    &:nth-child(2) {
      margin-bottom: rem(90px);
      transition-delay: 1.5s;
      .steps__item-bg {
        top: 38%;
        left: 53%;
        width: 92%;
      }
      .steps__item-img {
        width: rem(205px);
      }
      .steps__item-text {
      }
    }
    &:nth-child(3) {
      transition-delay: 3s;
      .steps__item-content {
      }
      .steps__item-bg {
        top: 15%;
        left: 58%;
        width: 85%;
      }
      .steps__item-img {
        width: rem(245px);
      }
      .steps__item-text {
      }
    }
    &-content {
      position: relative;
      z-index: 1;
    }
    &-img {
      margin-bottom: rem(8px);
    }
    &-text {
      font-size: rem(16px);
      line-height: rem(19px);
      font-family: $font-reg;
      letter-spacing: -0.02em;
      img {
        display: inline;
        height: rem(22px);
      }
      .btn {
        margin-top: rem(8px);
      }
    }
  }

  &__item-title {
    font-size: rem(16px);
    line-height: 1;
    color: $brown;
    text-transform: uppercase;
  }
  &__item-descr {
    margin: 0 auto;
    font-size: rem(14px);
    line-height: rem(16px);
    color: #fff;
    a {
      border-bottom: 1px solid currentColor;
    }
  }
  &__rules {
    margin: rem(0px) auto rem(35px);
    font-size: rem(14px);
    line-height: 1;
  }
  &__path {
    position: absolute;
    width: 180%;
    top: 9%;
    left: -50%;
    // transform: rotate(75deg);
    .path {
      stroke-dasharray: 3000;
      stroke-dashoffset: 3000;
      .active & {
        animation: dash 6s linear forwards;
      }
    }
  }
  @media (orientation: landscape) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: rem(185px) !important;
    padding-bottom: rem(50px);
    &::before {
      background-size: contain;
    }
    .container {
    }

    &__list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 0;
    }

    &__item {
      min-width: 30%;
      flex: 0 0 auto;
      margin: 0;
      width: auto;
      height: auto;
      // opacity: 0;
      transition: 0.4s;
      .active & {
        opacity: 1;
      }
      &-index {
        margin-left: 0;
        margin-right: rem(10px);
      }
      &-bg {
        width: auto;
      }
      &-img {
        margin-bottom: 0;
      }
      &-text {
        font-size: rem(24px);
        line-height: rem(29px);
        letter-spacing: -0.02em;
      }
      .btn {
        margin-top: rem(60px);
        background: #fff;
      }
      &:not(:last-of-type) {
        margin-bottom: rem(0px);
      }
      &:nth-child(1) {
        margin-top: rem(-30px);
        .steps__item-content {
        }
        .steps__item-bg {
          top: 30%;
          width: 82%;
        }
        .steps__item-img {
          width: rem(236px);
        }
        .steps__item-text {
          margin-top: rem(-3px);
        }
      }
      &:nth-child(2) {
        margin-top: rem(0px);
        margin-bottom: 0;
        transition-delay: 1.5s;
        .steps__item-bg {
          top: 22%;
          left: 50%;
          width: 87%;
        }
        .steps__item-img {
          width: rem(207px);
          margin-right: rem(10px);
        }
        .steps__item-text {
          margin-top: rem(2px);
        }
      }
      &:nth-child(3) {
        margin-top: rem(-65px);
        transition-delay: 3s;
        .steps__item-content {
          padding-right: rem(45px);
        }
        .steps__item-bg {
          top: 15%;
          left: 47%;
          width: 80%;
        }
        .steps__item-img {
          width: rem(251px);
        }
        .steps__item-text {
        }
      }
    }
    &__item-title {
      font-size: rem(20px);
      line-height: rem(22px);
    }
    &__path {
      position: absolute;
      width: 100vw;
      height: auto;
      top: auto;
      bottom: 18%;
      left: 0;
      transform: rotate(0deg);
    }
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
