<template>
  <form
    class="form-page-form__block"
    method="post"
    autocomplete="off"
    v-if="user"
  >
    <inputText
      label="Имя"
      :field="name"
      @input="name = $event"
      :class="{
        error: validationStatus($v.name) || errorMessage.name,
      }"
      :error="
        (!$v.name.required && $v.name.$error
          ? 'Обязательное поле'
          : !$v.name.rus && $v.name.$error
          ? 'Введите текст на русском языке'
          : '') || errorMessage.name
      "
    />
    <!--    <inputText-->
    <!--      label="Номер карты лояльности"-->
    <!--      placeholder="1234 4567 8910 1112"-->
    <!--      :mask="'#### #### #### ####'"-->
    <!--      :field="card"-->
    <!--      name="card"-->
    <!--      @input="card = $event"-->
    <!--      autocomplete="off"-->
    <!--      :class="{-->
    <!--        error: validationStatus($v.card) || errorMessage.card,-->
    <!--      }"-->
    <!--      :error="-->
    <!--        (!$v.card.minLength && $v.card.$error-->
    <!--          ? 'Минимум 16 символов'-->
    <!--          : '') || errorMessage.card-->
    <!--      "-->
    <!--    />-->
    <inputText
      label="Текущий пароль"
      type="password"
      :field="passwordOld"
      autocomplete="none"
      :class="{
        error: validationStatus($v.passwordOld) || errorMessage.passwordOld,
      }"
      :error="
        (!$v.passwordOld.required && $v.passwordOld.$error
          ? 'Обязательное поле'
          : '') || errorMessage.passwordOld
      "
      @input="passwordOld = $event"
    />
    <inputText
      label="Новый пароль"
      type="password"
      :field="passwordNew"
      autocomplete="new-password"
      :class="{
        error: validationStatus($v.passwordNew) || errorMessage.passwordNew,
        disabled: !passwordOld,
      }"
      :error="
        (!$v.passwordNew.minLength && $v.passwordNew.$error
          ? 'Минимум ' + $v.passwordNew.$params.minLength.min + ' символов'
          : '') || errorMessage.passwordNew
      "
      @input="passwordNew = $event"
    />
    <inputText
      label="Новый пароль еще раз"
      type="password"
      autocomplete="new-password"
      :field="passwordNewRepeat"
      :class="{
        error:
          validationStatus($v.passwordNewRepeat) ||
          errorMessage.passwordNewRepeat,
        disabled: !passwordOld || !passwordNew,
      }"
      :error="
        (!$v.passwordNewRepeat.sameAs && $v.passwordNewRepeat.$error
          ? 'Пароли должны совпадать'
          : '') || errorMessage.passwordNew
      "
      @input="passwordNewRepeat = $event"
    />

    <div class="form-page-form__footer">
      <button
        type="button"
        class="btn btn--primary btn--icon"
        @click="updateProfile()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Сохранить изменения
        <img src="../../assets/img/spinner.svg" class="loader" alt="" />
      </button>
      <div class="form-page__another-option">
        <p>
          <span @click="logout()" class="_undeline-link"
            >Выйти из аккаунта</span
          >
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "UserInfo",
  data() {
    return {
      ready: false,
      passwordOld: null,
      passwordNewRepeat: null,
      passwordNew: null,
      checkbox: true,
      errorMessage: {
        name: null,
        card: null,
        passwordOld: null,
        passwordNew: null,
        passwordNewRepeat: null,
        checkbox: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    name: { rus },
    passwordNewRepeat: {
      sameAs: sameAs(function () {
        return this.passwordNew;
      }),
    },
    passwordNew: { minLength: minLength(4) },
    passwordOld: { minLength: minLength(4) },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        name: null,
        card: null,
        passwordOld: null,
        passwordNew: null,
        passwordNewRepeat: null,
      };
    },

    close() {
      this.$store.commit("SHOW_POPUP_CONTENT", false);
    },
    switchToRecover() {
      this.$modal.hide("authorization");
      this.$modal.show("recover");
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    pushTo(link) {
      window.open(link, "_blank").focus();
    },
    updateProfile() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        const errors = Object.keys(this.errorMessage);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (this.$v[element].$error) {
            this.gtmEvent(element, "profile", "profile_error");
          }
        }
      }
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const data = {
          name: this.name,
          card: this.card,
          passwordOld: this.passwordOld,
          passwordNew: this.passwordNew,
          passwordNewRepeat: this.passwordNewRepeat,
        };
        this.$store
          .dispatch("UpdateProfile", data)
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                fieldsError = true;
                this.gtmEvent("email", "profile", "profile_error");
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
                this.gtmEvent("login", "profile", "profile_error");
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
                this.gtmEvent("name", "profile", "profile_error");
              }
              if (r.error && r.error.card) {
                [this.errorMessage.card] = r.error.card;
                fieldsError = true;
                this.gtmEvent("card", "profile", "profile_error");
              }
              if (r.error && r.error.passwordOld) {
                [this.errorMessage.passwordOld] = r.error.passwordOld;
                fieldsError = true;
                this.gtmEvent("passwordOld", "profile", "profile_error");
              }
              if (r.error && r.error.passwordNew) {
                [this.errorMessage.passwordNew] = r.error.passwordNew;
                fieldsError = true;
                this.gtmEvent("passwordNew", "profile", "profile_error");
              }
              if (r.error && r.error.passwordNewRepeat) {
                [this.errorMessage.passwordNewRepeat] =
                  r.error.passwordNewRepeat;
                fieldsError = true;
                this.gtmEvent("passwordNewRepeat", "profile", "profile_error");
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
                this.gtmEvent(r.error, "profile", "profile_error");
              }
            } else {
              this.gtmEvent("", "profile", "change_info");
              this.submitStatus = null;
              this.formIndex = this.formIndex += 1;
              this.$store.commit("SET_TOKEN", r.data.auth_key);
              this.$modal.show("common_success", {
                title: "Спасибо!",
                text: "Профиль успешно обновлен.",
              });
              this.getUserData();
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
    showSignUpModal() {
      this.$modal.hide("authorization");
      this.$modal.show("signup");
      // this.$modal.show("temporarily_unavailable");
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    name: {
      get() {
        return this.user.name;
      },
      set(val) {
        console.log(val);
        return val;
      },
    },
    email: {
      get() {
        return this.user.email;
      },
      set(val) {
        return val;
      },
    },
    card: {
      get() {
        return this.user.card;
      },
      set(val) {
        this.user.card = val;
      },
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  h3 {
    text-align: center;
    margin-top: rem(8px);
  }

  &__signin {
    width: 100%;
    margin: 0 auto;
    &-fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
    }
    .forget-link {
      white-space: nowrap;
      font-size: rem(14px);
      color: rgba(32, 0, 130, 1);
      text-align: right;
    }

    .forget-link:hover {
      text-decoration: none;
    }
  }
  &__checkbox {
    white-space: nowrap;
  }
  &__row {
    width: 100%;
    display: flex;
    margin-top: rem(10px);
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20px);
  }

  @media (orientation: landscape) {
    &__checkbox {
    }
    &__signin {
      &-fields {
      }
      .forget-link {
        margin: 0 0 0 auto;
        font-size: rem(18px);
        line-height: rem(29px);
      }
      &-footer {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        .btn {
          min-width: auto;
          margin: 0;
          &:not(:last-of-type) {
            margin-right: rem(24px);
          }
        }
      }
    }
    &__row {
      flex-direction: row;
      align-items: center;
      margin-bottom: rem(15px);
      margin-top: rem(10px);
    }
  }
}
</style>
