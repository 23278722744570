<template>
  <footer class="footer">
    <div class="container _small-width">
      <div class="footer__row">
        <div class="footer__col">
          <div class="footer__title">Нужна <br />помощь?</div>
          <button
            class="footer__feedback btn btn--bordered btn--lg _orange"
            @click="
              $modal.show('feedback');
              gtmEvent('help', 'help_click');
            "
          >
            Связаться с нами
          </button>
        </div>
        <div class="footer__col">
          <div class="footer__nav">
            <a
              :href="`${publicPath}docs/rules.pdf`"
              class="footer__nav-link"
              target="_blank"
              @click="gtmEvent('Пользовательское соглашение', 'footer')"
            >
              Правила
            </a>
            <router-link
              class="footer__nav-link"
              :to="{ name: 'Home', hash: '#partners' }"
              @click.native="gtmEvent('Акции', 'footer')"
            >
              Акции
            </router-link>
            <router-link
              class="footer__nav-link"
              :to="{ name: 'Home', hash: '#stories' }"
              @click.native="gtmEvent('Читать истории', 'footer')"
            >
              Читать истории
            </router-link>
            <router-link
              class="footer__nav-link"
              :to="{ name: 'Home', hash: '#products' }"
              @click.native="gtmEvent('Продукция', 'footer')"
            >
              Продукция
            </router-link>
            <!-- <router-link
              class="footer__nav-link"
              :to="{ name: 'Home', hash: '#retails' }"
            >
            Где купить
            </router-link> -->
          </div>
        </div>
        <div class="footer__col">
          <router-link
            v-if="user"
            class="footer__btn btn btn--primary btn--sm"
            :to="{ name: 'Profile' }"
            @click.native="gtmEvent('Мой кабинет', 'footer')"
          >
            <img src="../assets/images/icons/user-w.png" alt="" />
            <span>Мой кабинет</span>
          </router-link>
          <router-link
            v-else
            class="footer__btn btn btn--primary btn--sm"
            :to="{ name: 'Login' }"
            @click.native="gtmEvent('Мой кабинет', 'footer')"
          >
            <img src="../assets/images/icons/user-w.png" alt="" />
            <span>Мой кабинет</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footer__bottom">
        <span
          >Политика
          <a
            :href="`${publicPath}docs/policy.pdf`"
            target="_blank"
            class="_underlined"
            @click="gtmEvent('Политика конфиденциальности', 'footer')"
            >конфиденциальности</a
          ></span
        >
        <span
          >Политика обработки
          <a
            :href="`${publicPath}docs/user_agreement.pdf`"
            target="_blank"
            class="_underlined"
            @click="
              gtmEvent('Политика обработки персональных данных', 'footer')
            "
            >персональных данных</a
          ></span
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    toFeedback() {
      this.$modal.show("feedback");
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  padding: rem(63px) 0 rem(30px);
  text-align: left;
  background: #ffffff url("../assets/images/footer-bg-mob.svg") no-repeat left
    bottom;
  background-size: 100%;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: rem(50px);
    background: url("../assets/images/waves.png") no-repeat center top;
    background-size: auto 100%;
  }
  &__row,
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__bottom {
    margin-top: rem(44px);
    padding-top: rem(23px);
    font-family: $font-light;
    font-size: rem(14px);
  }
  &__nav {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    height: rem(148px);
    margin-top: rem(60px);
    margin-bottom: rem(30px);
  }

  &__nav-link {
    position: relative;
    margin-bottom: rem(30px);
    font-size: rem(18px);
    line-height: rem(18px);
    font-family: $font-reg;
    border-bottom: 1px solid transparent;
    transition: 0.4s;
  }

  &__nav-link:hover {
    border-color: currentColor;
  }
  &__title {
    margin-bottom: rem(35px);
    font-size: rem(48px);
    line-height: rem(44px);
  }
  &__text {
    color: rgba(184, 115, 76, 1);
    font-family: "Arial";
    font-size: rem(10px);
  }
  &__copyright {
    margin: rem(60px) auto 0;
    font-size: rem(12px);
    text-align: center;
    color: #a9918b99;
  }
  &__feedback {
    height: rem(70px);
  }
  &__bottom {
    margin-top: rem(150px);
    span {
      margin-bottom: rem(18px);
      font-size: rem(14px);
      color: #fff;
    }
  }
  &__btn.btn {
    display: flex;
    width: auto;
    position: relative;
    padding-left: rem(41px);
    padding-right: rem(20px);
    img {
      display: block !important;
      width: rem(12px);
      left: rem(10px);
      margin-right: rem(10px);
      transition: 0.4s;
    }
    &:hover {
      color: #fff;
    }
  }
  @media (orientation: landscape) {
    margin-top: rem(0px);
    padding: 0 0 rem(30px);
    background: #ffffff url("../assets/images/footer-bg.svg") no-repeat center
      bottom;
    background-size: 100%;
    &::before {
      height: rem(90px);
      background-size: 100%;
    }
    &__row,
    &__bottom {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__row {
    }
    &__bottom {
      margin-top: rem(230px);
      padding-top: rem(20px);
      font-size: rem(15px);
      span {
        margin-bottom: 0;
        font-size: rem(15px);
      }
    }
    &__feedback {
      padding: 0 rem(41px);
      letter-spacing: 0.05em;
    }
    &__btn {
      margin-top: rem(30px);
    }
    &__nav {
      flex-direction: column;
      margin-top: rem(30px);
      height: auto;
      margin-left: rem(150px);
      margin-bottom: 0;
    }
    &__nav-link {
      margin-bottom: rem(20px);
      font-size: rem(20px);
      line-height: 1;
    }
    &__col {
      flex: 0 1 auto;
      width: auto;
    }
    &__title {
      margin-bottom: rem(30px);
      font-size: rem(62px);
      line-height: rem(57px);
    }
    &__copyright {
      max-width: 30%;
      margin: rem(120px) auto 0;
      font-size: rem(14px);
    }
  }
}
</style>
