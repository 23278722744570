var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('form',{staticClass:"form-page-form__block",attrs:{"method":"post","autocomplete":"off"}},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name,
    },attrs:{"label":"Имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введите текст на русском языке'
        : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.passwordOld) || _vm.errorMessage.passwordOld,
    },attrs:{"label":"Текущий пароль","type":"password","field":_vm.passwordOld,"autocomplete":"none","error":(!_vm.$v.passwordOld.required && _vm.$v.passwordOld.$error
        ? 'Обязательное поле'
        : '') || _vm.errorMessage.passwordOld},on:{"input":function($event){_vm.passwordOld = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.passwordNew) || _vm.errorMessage.passwordNew,
      disabled: !_vm.passwordOld,
    },attrs:{"label":"Новый пароль","type":"password","field":_vm.passwordNew,"autocomplete":"new-password","error":(!_vm.$v.passwordNew.minLength && _vm.$v.passwordNew.$error
        ? 'Минимум ' + _vm.$v.passwordNew.$params.minLength.min + ' символов'
        : '') || _vm.errorMessage.passwordNew},on:{"input":function($event){_vm.passwordNew = $event}}}),_c('inputText',{class:{
      error:
        _vm.validationStatus(_vm.$v.passwordNewRepeat) ||
        _vm.errorMessage.passwordNewRepeat,
      disabled: !_vm.passwordOld || !_vm.passwordNew,
    },attrs:{"label":"Новый пароль еще раз","type":"password","autocomplete":"new-password","field":_vm.passwordNewRepeat,"error":(!_vm.$v.passwordNewRepeat.sameAs && _vm.$v.passwordNewRepeat.$error
        ? 'Пароли должны совпадать'
        : '') || _vm.errorMessage.passwordNew},on:{"input":function($event){_vm.passwordNewRepeat = $event}}}),_c('div',{staticClass:"form-page-form__footer"},[_c('button',{staticClass:"btn btn--primary btn--icon",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.updateProfile()}}},[_vm._v(" Сохранить изменения "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"form-page__another-option"},[_c('p',[_c('span',{staticClass:"_undeline-link",on:{"click":function($event){return _vm.logout()}}},[_vm._v("Выйти из аккаунта")])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }