<template>
  <div id="app">
    <Header v-if="showHeader" />
    <div class="app-content">
      <router-view />
    </div>
    <Footer v-if="showFooter" />
    <Feedback />
    <Signup />
    <Recover />
    <Authorization />
    <SuccessFeedback />
    <CommonError />
    <CommonSuccess />
    <CodeRegistration />
    <TemporarilyUnavailable />
    <VideoPopup />
  </div>
</template>
<script>
import VideoPopup from "./components/modals/videoPopup.vue";
import Recover from "./components/modals/recover.vue";
import Authorization from "./components/modals/authorization.vue";
import CodeRegistration from "./components/modals/codeRegistration.vue";
import CommonSuccess from "./components/modals/commonSuccess.vue";
import Signup from "./components/modals/signup.vue";
import Header from "@/components/AppHeader.vue";
import Footer from "@/components/AppFooter.vue";
import Feedback from "@/components/modals/feedback.vue";
import TemporarilyUnavailable from "@/components/modals/temporarilyUnavailable.vue";

import SuccessFeedback from "@/components/modals/successFeedback.vue";

import CommonError from "@/components/modals/commonError.vue";

export default {
  components: {
    Header,
    Footer,
    Feedback,
    SuccessFeedback,
    CommonError,

    TemporarilyUnavailable,
    Signup,
    CommonSuccess,
    CodeRegistration,
    Authorization,
    Recover,
    VideoPopup,
  },
  data: function () {
    return {
      showHeader: true,
      showFooter: true,
      apmInit: false,
      network: null,
    };
  },
  methods: {
    checkAuth(t) {
      if (this.$store.getters.token) {
        if (!this.$store.getters.user) {
          this.$store.dispatch("GetProfile").then((response) => {
            const { result, data } = response;

            if (!result) {
              // ...
            } else {
              window.dataLayer?.push({
                event: "authentication",
                UserID: data?.uuid,
              });
            }
          });
        }
      }
    },
    gtmEventsOnPages(page) {
      const widgetButton = document.querySelector(".info-button__button");
      const widgetScan = document.querySelector(".scan-button__button");
      widgetButton.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
      widgetScan.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
    },
  },
  mounted() {
    const t = this;
    const params = this.$route.query;
    if (params.email === "0") {
      let socId = params.identity_vk || params.identity_ok || "";

      console.log(socId);

      if (socId.length < 1) {
        this.$modal.show("common_error", {
          title: "Что-то пошло не так!",
        });
        this.$router.push("/");
      } else {
        this.$store.commit("SIGNUP_SOC_ID", socId);
        this.$modal.show("soc_signup");
        this.$router.push({
          name: "Home",
        });
      }
    } else if (params.first === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then((r) => {
        this.$modal.show("soc_signup-finish", {
          userName: r.data.profile.name,
        });
        this.$router.push({
          name: "Home",
        });
      });
    } else if (params.result === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then(() => {
        this.$router.push({
          to: "/",
        });
      });
    } else if (params["reset-password"] === "1") {
      // this.$router.push({
      //   name: "Home",
      // });
      this.$modal.show("change_password", { token: params.token });
    }

    this.checkAuth(t);
    if (!localStorage.getItem("first")) {
      this.$router.push({ name: "Verification" });
    }
  },
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "";
      },
      immediate: true,
    },
    "$route.name": function () {},
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}
.e-root {
  padding-left: rem(20px) !important;
  @media (min-width: $sm) {
    padding-bottom: 67px !important;
  }
}
.e-root__buttons {
  justify-content: flex-start !important;
  @media (max-width: $sm) {
    transform: scale(0.7);
    transform-origin: left bottom;
  }
  @media (min-width: $lg) {
    max-width: 95%;
    margin: auto;
  }
}
.scan-button__wrapper {
  @media (max-width: $sm) {
    margin-left: 3px !important;
  }
}
</style>
