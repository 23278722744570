<template>
  <section class="about section" id="about">
    <div class="container">
      <div class="about__content">
        <div class="about__title">
          Восхищение, гордость, смелость, любовь и&nbsp;доверие&nbsp;&mdash;
          такие важные и&nbsp;такие яркие чувства, которые окружают ваших детей
          каждый день.
        </div>
        <div class="about__text">
          Проводите время вместе, слушайте добрые сказки от&nbsp;Kinder вместе
          с&nbsp;ребёнком и&nbsp;помогайте ему осваивать удивительный мир
          эмоций. Добавляйте забавные стикеры в&nbsp;Telegram, чтобы делиться
          чувствами с&nbsp;родными!
        </div>
        <a
          v-if="user"
          class="btn btn--bordered"
          href="https://t.me/addstickers/Kinder_Emotions"
          target="_blank"
        >
          Открыть в Telegram
        </a>
        <router-link v-else class="btn btn--bordered" :to="{ name: 'Login' }">
          <span @click="gtmEvent('', 'main', 'open_tg', '')"
            >Открыть в Telegram</span
          >
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  padding: rem(72px) 0 rem(140px);
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: calc(100% + rem(100px));
    background: url("../assets/images/about-m.png") no-repeat center left 30%;
    background-size: auto 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86%;
    margin: auto;
    text-align: center;
  }
  &__title {
    margin-bottom: rem(16px);
    font-size: rem(18px);
    line-height: rem(22px);
    letter-spacing: -0.015em;
  }
  &__text {
    width: 90%;
    margin-bottom: rem(48px);
    font-size: rem(15px);
    line-height: rem(21px);
    font-family: $font-light;
    letter-spacing: -0.068em;
  }
  @media (orientation: landscape) {
    padding: rem(63px) 0 rem(200px);
    &::before {
      height: calc(100% + rem(230px));
      background: url("../assets/images/about.png") no-repeat center;
      background-size: auto 100%;
    }
    &__content {
      width: rem(533px);
    }
    &__title {
      margin-bottom: rem(40px);
      font-size: rem(28px);
      line-height: rem(32px);
      letter-spacing: -0.015em;
    }
    &__text {
      width: 84%;
      margin-bottom: rem(80px);
      font-size: rem(17px);
      line-height: rem(24px);
      font-family: $font-light;
      letter-spacing: -0.068em;
    }
  }
}
</style>
