<template>
  <section class="products section" id="products">
    <div class="products-bg-elements">
      <div class="products-bg-elements__item">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
            fill="#71C1DF"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item _no-animate">
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M44.3131 23.8526C41.8136 29.1704 36.4076 33.7889 30.7669 37.4401C25.1231 41.0916 19.2417 43.776 15.7819 45.2171C15.6049 45.2903 15.4156 45.2939 15.2444 45.244C15.0702 45.1943 14.9141 45.0911 14.8028 44.9359C10.5379 38.8517 -0.793008 23.8251 0.757906 12.199C1.13881 9.33368 2.14124 6.93708 3.73596 5.08066L3.78619 5.02259C6.92766 1.29752 10.8221 -0.273477 15.9293 0.567059C20.5678 1.32875 25.7203 4.85255 26.6242 10.9791C30.7303 6.41389 34.2208 6.17797 38.6117 7.67382C41.4696 8.64433 45.0409 11.4553 45.6893 16.1978L45.6922 16.2561C46.1151 19.2127 45.1775 22.0152 44.3131 23.8526Z"
            fill="#D00015"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#D20C1C"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M0.522917 3.80148C1.29369 1.14865 4.06801 -0.374685 6.71795 0.396022C9.36999 1.16734 10.8958 3.94133 10.1251 6.59415C9.35428 9.24698 6.5799 10.7702 3.92787 9.99891C1.27792 9.2282 -0.247852 6.4543 0.522917 3.80148Z"
            fill="#DA0D22"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.522917 3.80148C1.29369 1.14865 4.06801 -0.374685 6.71795 0.396022C9.36999 1.16734 10.8958 3.94133 10.1251 6.59415C9.35428 9.24698 6.5799 10.7702 3.92787 9.99891C1.27792 9.2282 -0.247852 6.4543 0.522917 3.80148Z"
            fill="#EFA800"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4025 25.8619L9.27527 18.3792L0.0784608 15.3856L7.55992 9.25443L10.5556 0.0556464L16.6829 7.5383L25.8767 10.531L18.3952 16.6622L15.4025 25.8619Z"
            fill="#18B6F0"
          />
        </svg>
      </div>
      <div class="products-bg-elements__item">
        <svg
          width="63"
          height="63"
          viewBox="0 0 63 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.6758 5.95886L40.8538 22.5449L57.5319 31.5582L40.9465 40.7419L31.9296 57.421L22.7514 40.8349L6.0788 31.8245L22.6642 22.6408L31.6758 5.95886Z"
            fill="#DA0D22"
          />
        </svg>
      </div>
    </div>
    <div class="container _small-width">
      <div class="products__title section-title">
        Выразите чувства, это <br class="hidden-xs" />так просто!
      </div>
      <div class="products__text">
        Kinder<sup class="reg">&reg;</sup> Сюрприз
      </div>
      <div class="products-list-wrapper">
        <VueSlickCarousel
          ref="slider"
          @beforeChange="beforeChange"
          v-bind="settings"
          class="products-list"
        >
          <div v-for="item in products" :key="item" class="products-list__item">
            <div class="products-list__item-card">
              <div class="products-list__item-img-wrapper">
                <img
                  :src="
                    require(`../assets/images/products/product-${item}.png`)
                  "
                  alt=""
                  class="products-list__item-img"
                />
              </div>
            </div>
          </div>
        </VueSlickCarousel>

        <div class="products__footer">
          <div class="products__counter">
            {{ active }} / {{ products.length }}
          </div>
          <router-link
            :to="{ name: 'Home', hash: '#retails' }"
            class="btn btn--primary btn--lg"
            >Купить</router-link
          >
        </div>
      </div>
      <div class="products__text">
        Kinder<sup class="reg">&reg;</sup> Шоколад
      </div>
      <VueSlickCarousel
        ref="slider2"
        @beforeChange="beforeChangeBottom"
        v-bind="settings"
        class="products-list _bottom"
      >
        <div
          v-for="item in productsShoco"
          :key="item"
          class="products-list__item"
        >
          <div class="products-list__item-card">
            <div class="products-list__item-img-wrapper">
              <img
                :src="require(`../assets/images/products/product-${item}.png`)"
                alt=""
                class="products-list__item-img"
              />
            </div>
          </div>
        </div>
      </VueSlickCarousel>

      <div class="products__footer _bottom">
        <div class="products__counter">
          {{ activeBottom }} / {{ productsShoco.length }}
        </div>
        <router-link
          :to="{ name: 'Home', hash: '#retails' }"
          class="btn btn--primary btn--lg"
          >Купить</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data: () => ({
    active: 1,
    activeBottom: 1,
    settings: {
      arrows: true,
      dots: false,
      slidesToShow: 3,
      initialSlide: 1,
      centerMode: true,
      draggable: false,
      centerPadding: "0px",
      infinite: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            infinite: false,
          },
        },
      ],
    },
    products: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    productsShoco: [1, 2, 3, 4, 5, 6],
  }),
  methods: {
    beforeChange(oldSlideIndex) {
      console.log(oldSlideIndex);
      this.active = oldSlideIndex + 1;
    },
    beforeChangeBottom(oldSlideIndex) {
      console.log(oldSlideIndex);
      this.activeBottom = oldSlideIndex + 1;
    },
  },
};
</script>

<style lang="scss">
.products {
  position: relative;
  padding-bottom: rem(230px);
  background: #faf5ed;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: rem(94px);
    position: absolute;
    bottom: 100%;
    left: 0;
    background: url("../assets/images/waves-3.svg") no-repeat left top;
    background-size: 250%;
  }
  @media (max-width: $md) {
    padding-top: rem(10px) !important;
    padding-bottom: rem(80px);
  }
  @media (orientation: landscape) {
    &::before {
      height: rem(94px);
      position: absolute;
      bottom: 100%;
      background: url("../assets/images/waves-3.svg") no-repeat left top;
      background-size: 100%;
    }
  }
  &__title.section-title {
    margin-bottom: rem(20px);
    @media (orientation: landscape) {
      margin-bottom: rem(20px);
    }
  }
  &__text {
    text-align: center;
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: 1;
    @media (orientation: landscape) {
      font-size: rem(24px);
    }
  }
  &-list {
    position: relative;
    width: calc(100% + rem(40px));
    margin-left: rem(-20px);
    margin-top: rem(20px);
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 140%;
      position: absolute;
      top: 0;
      left: 0;
      background: url("../assets/images/spot.svg") no-repeat center;
      background-size: 85%;
      z-index: -1;
    }
    @media (orientation: landscape) {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      height: rem(424px);
    }
    &._bottom {
      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 170%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("../assets/images/spots.png") no-repeat center bottom;
        background-size: 100%;
        z-index: -1;
        pointer-events: none;
      }
    }
    &-wrapper {
      margin-bottom: rem(113px);
      @media (orientation: landscape) {
        margin-bottom: rem(120px);
      }
    }
    .slick-track {
      display: flex !important;
      align-items: center;
    }
    .slick-slide {
      &:not(.slick-current) + .slick-active:not(.slick-current) {
        .products-list__item-card {
          margin-right: auto;
          margin-left: 0;
        }
      }
      &.slick-current + .slick-active {
        .products-list__item-card {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
    &__item {
      display: flex !important;
      &-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        height: rem(310px);
        ._bottom & {
          height: rem(250px);
        }
        @media (orientation: landscape) {
          height: rem(424px);
          transform: scale(0.7);
          transition: 0.3s;
          ._bottom & {
            height: rem(330px);
            width: 60%;
            transform: scale(1);
          }
          .slick-current & {
            transform: scale(1);
            ._bottom & {
              transform: scale(1.55);
              width: 100%;
            }
          }
        }
      }
      &-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: rem(23px);
        font-size: rem(28px);
        line-height: 0.9;
        span {
          width: 70%;
        }
      }
      &-index {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem(3px);
        @include size(rem(40px));
        border-radius: 50%;
        font-size: rem(16px);
        line-height: 1;
        background: rgba(#ffffff, 0.5);
      }
      &-img {
        @include size(auto, 100%);
        object-fit: cover;
        ._bottom & {
          @include size(90%, 100%);
          object-fit: contain;
          @media (orientation: landscape) {
            @include size(100%);
          }
        }
        &-wrapper {
          position: relative;
          display: flex;
          justify-content: center;
          @include size(100%);
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 83%;
            left: 50%;
            width: rem(170px);
            height: rem(18px);
            transform: translateX(-50%);
            background: url("data:image/svg+xml,%3Csvg width='170' height='18' viewBox='0 0 170 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='85.418' cy='9' rx='84.5' ry='9' fill='url(%23paint0_radial_1368_36922)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_1368_36922' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(85.418 9) rotate(90) scale(9 84.5)'%3E%3Cstop stop-color='%23B3B3B3'/%3E%3Cstop offset='1' stop-color='%23D9D9D9' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E%0A")
              no-repeat center;
            z-index: -1;
          }
          ._bottom & {
            &::before {
              display: none;
            }
          }
        }
      }
      &-status {
        display: flex;
        align-items: center;
        margin-top: rem(22px);
        svg {
          margin-right: rem(7px);
          margin-left: rem(-19px);
        }
      }
      .btn {
        width: rem(214px);
        margin-top: rem(20px);
      }
    }
  }
  &-bg-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      transform: scale(0.7);
      animation: rotareStar 4s linear infinite forwards normal;
      svg {
        @include size(100%);
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &._no-animate {
        animation: none;
      }
      &:nth-child(1) {
        top: rem(200px);
        left: 18%;
        @include size(rem(49px));
      }
      &:nth-child(2) {
        top: rem(240px);
        left: 85%;
      }
      &:nth-child(3) {
        top: rem(660px);
        left: 33%;
      }
      &:nth-child(4) {
        top: rem(240px);
        left: 74%;
      }
      &:nth-child(5) {
        top: rem(340px);
        left: 20%;
        @media (orientation: landscape) {
          left: 7%;
        }
      }
      &:nth-child(6) {
        top: rem(670px);
        left: 86%;
      }
      &:nth-child(7) {
        top: rem(610px);
        left: 12%;
      }
      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(-13px);
    &._bottom {
      margin-top: rem(8px);
    }
    @media (orientation: landscape) {
      margin-top: rem(-35px);
      &._bottom {
        margin-top: rem(-80px);
      }
    }
  }
  &__counter {
    margin-bottom: rem(20px);
    font-family: $font-reg;
    font-size: rem(16px);
  }
  .btn {
    margin: auto;
    @media (max-width: $md) and (orientation: landscape) {
      width: auto;
    }
    @media (min-width: $md) and (orientation: landscape) {
      width: rem(132px);
    }
  }
}
</style>
