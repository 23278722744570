<template>
  <section class="section retails" id="retails">
    <div class="container _small-width">
      <div class="retails-content">
        <h2 class="section-title retails-title">Где купить</h2>
        <div class="retails-list">
          <a
            v-for="(item, i) in retails"
            :key="i"
            :href="item.link"
            target="_blank"
            class="retails-list__item"
            @click="gtmEvent(item.gtm.label)"
          >
            <img :src="require(`../assets/images/logos/${item.logo}`)" alt="" />
            <span>{{ item.title }}</span>
          </a>
        </div>
        <Sun class="hidden-xs" />
      </div>
    </div>
  </section>
</template>

<script>
import Sun from "../components/AppSun.vue";
export default {
  components: {
    Sun,
  },
  data: () => ({
    retails: [
      {
        logo: "vprok.png",
        title: "Впрок",
        gtm: {
          label: "shop_sber_market",
        },
        link: "https://www.vprok.ru/product/kinder-shok-kinder-choc-mol-s-mol-nach-50g--304348?searchPhrase=%D0%BA%D0%B8%D0%BD%D0%B4%D0%B5%D1%80",
      },
      {
        logo: "ozon.png",
        title: "Ozon",
        gtm: {
          label: "shop_ozon",
        },
        background: "#0069FF",
        link: "https://www.ozon.ru/product/shokoladnoe-yaytso-kinder-syurpriz-s-igrushkoy-vnutri-20g-264707908/?advert=DgNlrzqBptm6ADPKlTFSwJKr-FSqzlWPEKM-5ytIXTHH9dV2cBXV-NEsQa8WDy5dkUrvAGsAwybjt6d8f_NRXwGv3pWeADaDADw9axIKEMTaDR_lQWQ7elQWEnjnkIlWo11Q1fySr1i-KukGuWapi0RA6eCz18S5-bRnJV9-Nc4nhcW111_MVF6HcajkExnRurtKDflCpja1HzeTmw1PIbhXy0edgs9l7DZVpU6zE23VN6BUIxgWRROvVKtqEbFntj4Mqlg8lYeppf-E92BwNiAUzaX9UudQGgEvNtWTw5LJyTuBXJO--8L_GUg4z9Q9bueb_Vs&avtc=2&avte=1&avts=1715679167&ectx=1&keywords=%D1%81%D1%8E%D1%80%D0%BF%D1%80%D0%B8%D0%B7+%D0%BA%D0%B8%D0%BD%D0%B4%D0%B5%D1%80&miniapp=supermarket",
      },
      {
        logo: "perekrestok.png",
        title: "Перекрёсток",
        gtm: {
          label: "shop_perekrestok",
        },
        link: "https://www.perekrestok.ru/cat/195/p/sokolad-molocnyj-kinder-chocolate-s-molocnoj-nacinkoj-50g-17342",
      },
      {
        logo: "5ka.png",
        title: "Пятерочка",
        gtm: {
          label: "shop_pyaterochka",
        },
        link: "https://5ka.ru/",
      },
      {
        logo: "lavka.png",
        title: "Яндекс. Лавка",
        gtm: {
          label: "shop_yandex_market",
        },
        link: "https://lavka.yandex.ru/213/good/shokolad-kinder-chocolate-s-molochnoj-nachinkoj-50-gram",
      },
      {
        logo: "samokat.png",
        title: "Самокат",
        gtm: {
          label: "shop_samokat",
        },
        background: "#FF486F",
        link: "https://samokat.ru/product/39e5b9aa-fa98-11e8-80c5-0cc47a817925",
      },
    ],
  }),
  mounted() {
    this.$parent.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.retails {
  background: #faf5ed;
  @media (max-width: $md) {
    padding-bottom: rem(80px);
    padding-top: rem(110px);
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(30px);
    background: #8fe2f6 url("../assets/images/doodle.png") no-repeat center;
    background-size: 100%;
    border-radius: rem(35px);
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    gap: rem(20px);
    padding-top: rem(15px);
    &__item {
      position: relative;
      display: flex;
      width: auto;
      height: rem(61px);
      align-items: center;
      justify-content: center;
      padding: 0 rem(47px) 0 rem(24px);
      background: #fff;
      border-radius: rem(60px);
      font-family: $font-reg;
      font-size: rem(18px);
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: rem(23px);
        transform: translate(-50%, -50%);
        display: block;
        @include size(rem(10px));
        background: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.91797 1.5L4.91797 4.5L1.91797 7.5' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          no-repeat center;
        background-size: contain;
        transition: 0.4s;
      }
      &:hover {
        &::after {
          transform: translate(0%, -50%);
        }
      }
      @media (min-width: $sm) {
        padding: 0 rem(53px) 0 rem(24px);
        height: rem(60px);
      }

      img {
        @include size(rem(29px));
        margin-right: rem(11px);
      }
    }
  }
  .sun {
    position: absolute;
    top: 90%;
    left: 90%;
  }
  @media (orientation: landscape) {
    padding-bottom: rem(213px);
    &-title {
      margin-bottom: rem(55px);
    }
    &-content {
      margin-top: rem(-5px);
      padding: rem(64px) rem(105px) rem(50px);
    }
    &-list {
      width: 90%;
      flex-direction: row;
      justify-content: center;
      gap: rem(24px);
      margin-top: 0;
      padding-top: 0;
      &__item {
        font-size: rem(18px);
        &::after {
          right: rem(30px);
        }
      }
      &__title {
        margin-bottom: rem(29px);
        font-size: rem(32px);
        line-height: rem(36px);
      }
    }
  }
  .sun {
    top: 40%;
    left: 83%;
    @include size(rem(344px));
  }
}
</style>
