<template>
  <Modal name="video" @closeModal="closeModal" size="sm" classes="">
    <template v-slot:content>
      <div class="modal__iframe">
        <video
          :src="`${publicPath}video/${count}.mp4`"
          playsinline
          controls
          id="video"
        ></video>
        <img
          v-if="firstPlay"
          class="modal__iframe-preview"
          :src="`${publicPath}video/preview/${count}.jpg`"
          alt=""
        />
        <div class="modal__iframe-nav" :class="{ hide: hideNav }">
          <div
            @click="prev()"
            :class="{ hide: count === 1 }"
            class="modal__iframe-nav-prev"
          >
            <img src="../../assets/images/icons/prev.svg" alt="" />
            <span>Предыдущая история </span>
          </div>
          <div @click="playVideo" class="modal__iframe-nav-play">
            <img
              v-if="hideNav"
              src="../../assets/images/icons/pause.svg"
              alt=""
            />
            <img v-else src="../../assets/images/icons/play.svg" alt="" />
          </div>
          <div
            @click="next()"
            :class="{ hide: count === 6 }"
            class="modal__iframe-nav-next"
          >
            <span>Следующая история</span>
            <img src="../../assets/images/icons/next.svg" alt="" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      src: null,
      count: null,
      firstPlay: true,
      hideNav: false,
      percentageCompleted: 0,
      videoStarted: false,
      videoTwentyFive: false,
      videoFifty: false,
      videoSeventyFive: false,
      videoComplete: false,
      totalLength: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.src = event.params.src;
      this.count = event.params.count;
      this.hideNav = false;
      this.firstPlay = true;
      this.videoStarted = true;
      this.videoTwentyFive = false;
      this.videoFifty = false;
      this.videoSeventyFive = false;
      this.percentageCompleted = 0;
      this.videoSeventyFive = false;
      this.totalLength = null;
    },
    prev() {
      let i = this.count;
      this.gtmEvent("", "stories", "story_prev", i);
      if (i > 1 && i < 7) {
        this.count--;
      }
      this.hideNav = false;
      this.firstPlay = true;
      this.videoStarted = true;
      this.videoTwentyFive = false;
      this.videoFifty = false;
      this.videoSeventyFive = false;
      this.percentageCompleted = 0;
      this.videoSeventyFive = false;
      this.totalLength = null;
    },
    next() {
      let i = this.count;
      this.gtmEvent("", "stories", "story_next", i);
      if (i < 7) {
        this.count++;
      }
      this.hideNav = false;
      this.firstPlay = true;
      this.videoStarted = true;
      this.videoTwentyFive = false;
      this.videoFifty = false;
      this.videoSeventyFive = false;
      this.totalLength = null;
    },
    videoIsFinished(e) {
      let arr = localStorage.getItem("stories");
      if (!arr) {
        arr = this.count;
      }
      console.log(arr);
      if (arr && !arr.toString().includes(this.count)) {
        arr = arr += `, ${this.count}`;
      }
      localStorage.setItem("stories", arr);
      this.$store.commit("SET_STORIES_COUNT", arr);
      if (!this.$store.getters.user) {
        return;
      }
      this.$store
        .dispatch("Story", { id: this.count })
        .then((r) => {
          if (!r.result) {
            if (r.error) {
              this.$modal.show("common_error", {
                text: "Что-то пошло не так! " + r.error,
              });
            }
          }
        })
        .catch(() => {});
      this.gtmEvent("", "stories", "story_end", this.count);
    },

    playVideo() {
      const $this = this;
      this.hideNav = !this.hideNav;
      const video = document.getElementById("video");

      if (this.hideNav) {
        this.firstPlay = false;
        video.play();
        this.gtmEvent("", "stories", "story_watch", this.count);

        video.ontimeupdate = function () {
          $this.totalLength = video.duration;
          $this.percentageCompleted = (video.currentTime / $this.totalLength) * 100;
          
          // is 25
          if (!$this.videoTwentyFive && $this.percentageCompleted > 25) {
            console.log("VIDEO_25");
            $this.videoTwentyFive = true;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "interaction",
              site_section: "stories",
              action: "story_watch_perc",
              label: 25,
              eventContext: $this.count
            });
          }
          // is 50
          if (!$this.videoFifty && $this.percentageCompleted > 50) {
            console.log("VIDEO_50");
            $this.videoFifty = true;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "interaction",
              site_section: "stories",
              action: "story_watch_perc",
              label: 50,
              eventContext: $this.count
            });
          }
          // is 75
          if (!$this.videoSeventyFive && $this.percentageCompleted > 75) {
            console.log("VIDEO_75");
            $this.videoSeventyFive = true;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "interaction",
              site_section: "stories",
              action: "story_watch_perc",
              label: 75,
              eventContext: $this.count
            });
          }
          // is 100
          if (!$this.videoComplete && $this.percentageCompleted > 99) {
            console.log("VIDEO_100");
            $this.videoComplete = true;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "interaction",
              site_section: "stories",
              action: "story_watch_perc",
              label: 100,
              eventContext: $this.count
            });
          }
        };
        video.addEventListener("ended", function (e) {
          $this.videoIsFinished();
        });
      } else {
        this.firstPlay = false;
        video.pause();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@media (max-width: 1023px) and (orientation: portrait)
  .modal__iframe
    video
      display: none

.modal__iframe-preview
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 100dvh
  object-fit: cover
</style>
