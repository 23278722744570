<template>
  <label class="form-textarea">
    <div class="form__caption" v-if="label">{{ label }}</div>
    <textarea
      class="form-textarea__field"
      :placeholder="placeholder"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    ></textarea>

    <div class="error-hint" v-if="error">{{ error }}</div>
  </label>
</template>
<script>
export default {
  data() {
    return {
      retValue: this.field,
      nameValue: this.name || "",
    };
  },
  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: String,
    name: String,
    label: {
      type: String,
    },
    error: {
      type: String,
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.form-textarea {
  display: block;
  max-width: 100%;
  width: 100%;

  &__field {
    resize: none;
    display: block;
    min-height: rem(100px);
    width: 100%;
    font-size: rem(14px);
    line-height: 1.2rem;
    letter-spacing: 0.01em;
    border: 1px solid rgba($grey, 0.4);
    font-family: $font-reg;
    color: $grey;
    box-sizing: border-box;
    border-radius: rem(20px);
    padding: rem(8px) rem(10px);
    outline: none !important;
    transition: all 0.3s ease-in-out;
    &::placeholder {
      color: #806f6a;
    }
    &:focus {
      &::placeholder {
        color: #fff;
      }
    }
  }

  &__caption {
    font-size: rem(14px);
    line-height: rem(16px);
    color: #000;
    text-transform: none;
    margin-bottom: rem(3px);
  }
  @media (orientation: landscape) {
    &__field {
      min-height: rem(90px);
      padding: rem(20px) rem(15px);
      font-size: $field-font-size-lg;
    }
  }
}
</style>
