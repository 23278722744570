<template>
  <div class="form-page verification">
    <transition name="fade">
      <div v-if="show" class="verification-window form-page-form-wrapper">
        <div class="form form-page-form">
          <div class="verification-window__title form-page__title">
            Добро пожаловать
          </div>
          <div class="verification-window__text form-page__text">
            Для посещения этого веб-сайта вам должно быть больше 13&nbsp;лет
          </div>

          <checkBox
            label="Подтверждаю, что мне больше 13 лет"
            :field="checkbox"
            @input="checkbox = $event"
          />

          <div class="verification-window__buttons">
            <button
              :disabled="!checkbox"
              @click="setLS()"
              class="verification-window__btn btn btn--primary"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import checkBox from "@/components/form/checkBox.vue";

export default {
  components: { checkBox },
  data: () => ({
    show: false,
    checkbox: false,
  }),
  methods: {
    setLS() {
      const params = this.$route.query;
      localStorage.setItem("first", true);
      this.gtmEvent("more_than_13", "age_verification");
      this.$router.push({ name: "Home" });
      this.$parent.showFooter = true;
    },
  },
  mounted() {
    this.$parent.showFooter = false;
    this.$parent.showHeader = false;
    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
.form-page-form-wrapper {
  width: rem(524px);
  padding: rem(91px);

  @media (orientation: portrait) {
    border-radius: 0;
    padding: rem(20px);
  }
}

.verification {
  background: #fdde77;
  padding: 0;
  &::before,
  &::after {
    display: none;
  }
  &-window {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &__buttons {
      width: rem(380px);
      display: flex;
      margin-top: rem(22px);
      .btn {
        width: rem(200px);
        margin: 0 rem(20px) 0 0;

        &[disabled] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
    &__title {
      margin-bottom: rem(20px);
    }
    &__text {
      text-align: left;
      opacity: 0.7;
      margin-bottom: rem(22px);
      @media (orientation: portrait) {
        font-size: rem(14px);
      }
    }
  }
}
</style>
