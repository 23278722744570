<template>
  <section class="partners section" id="partners">
    <div class="container _small-width">
      <div class="section-title">
        Участвуйте в&nbsp;акциях<br />наших партнеров
      </div>
      <VueSlickCarousel
        ref="slider"
        @afterChange="afterChange"
        v-bind="settings"
        class="partners-list"
      >
        <div v-for="(item, i) in partners" :key="i" class="partners-list__item">
          <div class="partners-list__item-card" :class="item.className">
            <div class="partners-list__item-content">
              <div class="partners-list__item-title" v-html="item.title"></div>
              <div class="partners-list__item-text" v-html="item.text"></div>
              <a
                :href="item.link"
                @click="gtmEvent('', 'main', 'partner_click', item.name)"
                target="_blank"
                class="btn btn--white btn--lg _orange"
              >
                Участвовать
              </a>
            </div>
            <div class="partners-list__item-img-wrapper">
              <img
                :src="require(`../assets/images/partners/${item.img}`)"
                alt=""
                class="partners-list__item-img"
              />
              <img
                :src="require(`../assets/images/partners/${item.imgMobile}`)"
                alt=""
                class="partners-list__item-img -mobile"
              />
            </div>
            <img
              v-if="item.logo"
              :src="require(`../assets/images/partners/${item.logo}`)"
              alt=""
              class="partners-list__item-logo"
            />
          </div>
          <div class="partners__note" v-html="item.disclaimer"></div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      slidesToShow: 1,
      infinite: true,
      fade: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            infinite: false,
          },
        },
      ],
    },
    partners: [
      {
        img: "ill-1.png",
        name: "magnit",
        imgMobile: "m.png",
        logo: "magnit.svg",
        title: "Выигрывайте призы в&nbsp;Магните",
        text: "Главный приз&nbsp;&mdash; сертификат номиналом 200&nbsp;000&nbsp;руб. в&nbsp;парк развлечений",
        className: "_yellow-bg",
        link: "https://www.promo-magnit.ru",
        disclaimer:
          'Реклама. Организатор акции ООО &laquo;Фэмели&raquo;. С&nbsp;01.05.2024 по&nbsp;30.06.2024 покупайте любые продукты Kinder<sup class="reg">&reg;</sup> на&nbsp;сумму от&nbsp;150 рублей в&nbsp;одном чеке и&nbsp;участвуйте в&nbsp;розыгрыше призов. Общий срок акции: с&nbsp;01.05.2024 по&nbsp;31.08.2024 (включая срок вручения призов). Есть ограничения. Информация об&nbsp;организаторе акции, правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения размещена на&nbsp;promo-magnit.ru. Организатор вправе приостановить, прекратить досрочно проведение акции или изменить другие ее&nbsp;условия.',
      },
      {
        img: "ill-2.png",
        name: "perekrestok",
        imgMobile: "p.png",
        logo: "perekrestok.svg",
        title: "Выигрывайте призы в&nbsp;Перекрестке",
        text: "Главный приз&nbsp;&mdash; сертификат номиналом 200&nbsp;000&nbsp;руб. в&nbsp;парк развлечений &laquo;Сказка&raquo;",
        className: "_blue-bg",
        link: "https://perekrestok.promo-x5.ru",
        disclaimer:
          'Реклама. Организатор акции ООО &laquo;Фэмели&raquo;. С&nbsp;01.05.2024 по&nbsp;30.06.2024 покупайте любые продукты Kinder<sup class="reg">&reg;</sup> на&nbsp;сумму от&nbsp;150 рублей в&nbsp;одном чеке и&nbsp;участвуйте в&nbsp;розыгрыше призов. Общий срок акции: с&nbsp;01.05.2024 по&nbsp;30.09.2024 (включая срок вручения призов). Есть ограничения. Информация об&nbsp;организаторе акции, правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения размещена на&nbsp;www.perekrestok.promo-x5.ru. Организатор вправе приостановить, прекратить досрочно проведение акции или изменить другие ее&nbsp;условия.',
      },
      {
        img: "ill-2.png",
        name: "pyaterochka",
        imgMobile: "p1.png",
        logo: "5ka.svg",
        title: "Выигрывайте призы в&nbsp;Пятерочке",
        text: "Главный приз&nbsp;&mdash; сертификат номиналом 200&nbsp;000&nbsp;руб. в&nbsp;парк развлечений &laquo;Сказка&raquo;",
        className: "_blue-dark-bg",
        link: "https://5ka.promo-x5.ru",
        disclaimer:
          'Реклама. Организатор акции ООО &laquo;Фэмели&raquo;. С&nbsp;01.05.2024 по&nbsp;30.06.2024 покупайте любые продукты Kinder<sup class="reg">&reg;</sup> на&nbsp;сумму от&nbsp;150 рублей в&nbsp;одном чеке и&nbsp;участвуйте в&nbsp;розыгрыше призов. Общий срок акции: с&nbsp;01.05.2024 по&nbsp;31.08.2024 (включая срок вручения призов). Есть ограничения. Информация об&nbsp;организаторе акции, правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения размещена на&nbsp;www.5ka.promo-x5.ru. Организатор вправе приостановить, прекратить досрочно проведение акции или изменить другие ее&nbsp;условия.',
      },
    ],
  }),
  methods: {
    afterChange(slideIndex) {},
  },
};
</script>

<style lang="scss" scoped>
.partners {
  padding-top: rem(28px);
  padding-bottom: rem(110px);
  @media (orientation: landscape) {
    padding-top: rem(110px);
    padding-bottom: rem(155px);
  }
  &-list {
    width: calc(100% + rem(40px));
    margin-left: rem(-20px);
    padding-top: rem(15px);
    margin-bottom: rem(10px);
    @media (orientation: landscape) {
      width: 100%;
      margin-left: 0;
      padding-top: rem(7px);
      margin-bottom: rem(20px);
    }
    &__item {
      @media (max-width: $md) {
        padding: 0 rem(20px);
      }
      &-card {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: rem(408px);
        padding: rem(60px) rem(90px);
        border-radius: rem(35px);
        overflow: hidden;
        transition: 0.4s;
        &._yellow-bg {
          background: #fdde77;
        }
        &._blue-bg {
          background: #45c6e6;
        }
        &._blue-dark-bg {
          background: #0365d4;
          color: #fff;
        }
        &._orange-bg {
          background: #f9c5a6;
        }
        @media (max-width: $md) {
          height: rem(618px);
          padding: rem(0px);
          flex-direction: column;
        }
      }
      &-content {
        width: rem(334px);
        @media (max-width: $md) {
          width: 100%;
          padding: rem(30px);
        }
      }
      &-title {
        width: 100%;
        margin-bottom: rem(20px);
        font-size: rem(28px);
        line-height: rem(26.6px);
        letter-spacing: -0.01em;
        span {
          width: 70%;
        }
        @media (max-width: $md) {
          font-size: rem(24px);
          line-height: rem(25px);
        }
      }
      &-text {
        width: 80%;
        margin-bottom: rem(30px);
        font-family: $font-reg;
        font-size: rem(20px);
        line-height: rem(21px);
        letter-spacing: -0.01em;
        @media (max-width: $md) {
          margin-bottom: rem(20px);
          font-size: rem(16px);
          line-height: rem(19px);
        }
      }
      &-img {
        @include size(100%);
        object-fit: cover;
        @media (max-width: $md) {
          object-position: 70% 50%;
          display: none !important;

          &.-mobile {
            display: block !important;
          }
        }
        &-wrapper {
          width: rem(335px);
          height: rem(342px);
          z-index: 0;
          @media (orientation: landscape) {
            position: absolute;
            top: 0;
            right: 0;
            width: 70%;
            height: 100%;
          }
        }
      }
      &-logo {
        position: absolute;
        right: rem(20px);
        top: rem(20px);
        width: rem(98px);
        @media (max-width: $md) {
          top: auto;
          width: rem(98px);
          right: rem(-4px);
          bottom: rem(205px);
          display: none !important;
        }
      }
      .btn {
        @media (orientation: landscape) {
          padding: 0 rem(35px);
        }
      }
    }
  }
  &__note {
    font-size: rem(10px);
    line-height: rem(15px);
    font-family: $font-light;
    padding-top: rem(20px);
    @media (orientation: landscape) {
      font-size: rem(12px);
      line-height: rem(15px);
    }
  }
}
</style>
