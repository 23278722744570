<template>
  <div class="form-page">
    <transition name="fade">
      <div
        v-if="show"
        class="form-page-form-wrapper form-page-form-wrapper--success"
      >
        <div class="form-page__success">
          <div class="form-page__title _centered">
            Регистрация <br />прошла успешно
          </div>
          <router-link
            :to="{ name: 'Home' }"
            type="button"
            class="btn btn--primary"
          >
            На главную
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    show: false,
  }),
  mounted() {
    this.$parent.showFooter = false;
    this.show = true;
    this.gtmEvent(
      "",
      "registration",
      "registration_success",
      "step_registration_success"
    );
  },
};
</script>

<style lang="sass" scoped>
.form-page__success
  margin: auto
</style>
