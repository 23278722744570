<template>
  <section class="section stories" id="stories">
    <div class="container">
      <div class="stories__block">
        <div class="stories__title">Испытывайте добрые эмоции</div>
        <div class="stories__text">
          получайте стикеры в&nbsp;Telegram и&nbsp;открытку в&nbsp;подарок
        </div>
        <router-link
          :to="{ name: 'Home', hash: '#all-stories' }"
          class="stories__btn btn btn--white btn--lg _orange"
        >
          <span
            @click="gtmEvent('', 'stories', 'stories_click', 'kind_emotions')"
            >Читать истории</span
          >
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: rem(710px);
  background: #fff url("../assets/images/animals-sm.png") no-repeat center
    bottom;
  background-size: 100%;
  @media (orientation: landscape) {
    padding-top: 0;
    height: rem(740px);
    align-items: center;
    background: #fff url("../assets/images/animals.png") no-repeat center 0;
    background-size: auto 100%;
  }
  &__block {
    position: relative;
    width: 100%;
    padding: rem(30px) rem(30px) rem(77px);
    margin: rem(70px) 0 0;
    background: #fdde77;
    border-radius: rem(20px);
    letter-spacing: -0.02em;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: rem(130px);
      display: block;
      width: rem(223px);
      height: rem(148px);
      background: url("../assets/images/rainbow-2.png") no-repeat center bottom;
      background-size: contain;
    }
    @media (orientation: landscape) {
      width: rem(465px);
      padding: rem(40px) rem(40px) rem(125px);
      margin: 0 0 rem(10px) rem(75px);
      border-radius: rem(35px);
      &::before {
        left: rem(166px);
        width: rem(395px);
        height: rem(287px);
      }
    }
  }
  &__title {
    max-width: 80%;
    margin-bottom: rem(17px);
    font-size: rem(24px);
    line-height: rem(25px);
    @media (orientation: landscape) {
      max-width: 100%;
      margin-bottom: rem(20px);
      font-size: rem(40px);
      line-height: rem(42px);
    }
  }
  &__text {
    margin-bottom: rem(23px);
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: rem(20px);
    @media (orientation: landscape) {
      margin-bottom: rem(30px);
      font-size: rem(24px);
      line-height: rem(29.5px);
    }
  }
  &__btn {
    font-family: $font-bold;
    position: relative;
    z-index: 1;
    @media (max-width: $md) {
      height: rem(52px);
      font-size: rem(16px);
      padding: 0 rem(40px);
    }
  }
}
</style>
