<template>
  <div class="form-page">
    <transition name="fade">
      <div v-if="show" class="form-page-form-wrapper">
        <div class="form form-page-form">
          <div class="form-page__title">Регистрация</div>
          <signup-form></signup-form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SignupForm from "../../components/form/SignupForm.vue";
export default {
  components: { SignupForm },
  data: () => ({
    show: false,
  }),
  mounted() {
    this.$parent.showFooter = false;
    this.show = true;
  },
};
</script>
