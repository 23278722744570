<template>
  <section class="postcard section">
    <div class="container">
      <div class="postcard__row">
        <div class="postcard__content">
          <div class="postcard__title section-title">Удивите открыткой</div>
          <div class="postcard__text">
            Создайте свою уникальную открытку с&nbsp;иллюстрациями из&nbsp;наших
            лесных историй
          </div>
          <router-link
            :to="{ name: $store.getters.user ? 'Postcards' : 'Login' }"
            class="btn btn--primary btn--lg"
          >
            <span @click="gtmEvent('', 'main', 'postcard_start', '')"
              >Скачать открытку</span
            >
          </router-link>
        </div>
        <img
          src="../assets/images/illustration.png"
          alt=""
          class="postcard__img"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.postcard {
  padding-top: rem(95px);
  padding-bottom: rem(95px);
  background: url("../assets/images/waves-4.svg");
  background-size: auto 100%;
  @media (orientation: landscape) {
    padding-top: rem(120px);
    padding-bottom: rem(110px);
    background: url("../assets/images/waves-2.svg");
    background-size: auto 100%;
  }
  &__row {
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  &__img {
    width: 100%;
    margin-top: rem(30px);
    opacity: 0;
    .active & {
      animation: fade-in-right 1s ease-in forwards;
    }
    @media (orientation: landscape) {
      width: rem(720px);
    }
  }
  &__content {
    width: 70%;
    @media (orientation: landscape) {
      width: rem(330px);
      margin-left: rem(110px);
      margin-top: rem(100px);
    }
  }
  &__title {
    margin-bottom: rem(20px);
    text-align: left;
    @media (orientation: landscape) {
      margin-bottom: rem(26px);
    }
  }
  &__text {
    margin-bottom: rem(20px);
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: rem(20px);
    @media (orientation: landscape) {
      margin-bottom: rem(32px);
      font-size: rem(24px);
      line-height: rem(29px);
    }
  }
}
</style>
