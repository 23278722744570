<template>
  <div class="home">
    <Hero />
    <About />
    <Steps />
    <AppStories />
    <AppAllStories />
    <AppStickers />
    <AppPostcard />
    <AppPartners />
    <AppProducts />
    <AppWhereToBuy />
  </div>
</template>

<script>
import About from "../components/AppAbout.vue";
import AppProducts from "../components/AppProducts.vue";
import AppPartners from "../components/AppPartners.vue";
import AppPostcard from "../components/AppPostcard.vue";
import AppStickers from "../components/AppStickers.vue";
import AppAllStories from "../components/AppAllStories.vue";
import AppStories from "../components/AppStories.vue";
import AppWhereToBuy from "../components/AppWhereToBuy.vue";
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
export default {
  data: function () {
    let self = this;
    let delay = 1000; //milliseconds
    let timeoutId;
    let animationIsFinished = false;
    return {
      publicPath: process.env.BASE_URL,
      currentSection: null,
      parameters: {
        event: "gaEvent",
        category: "Check_reg_main_form_CB",
        action: "Check_reg_main_form_CB",
        label: "main",
      },
    };
  },

  mounted() {
    this.$parent.showHeader = true;
    this.$parent.showFooter = true;
    const $this = this;
    const sections = document.querySelectorAll(".section");

    window.addEventListener("scroll", () => {
      let scrollPosition = window.scrollY + 80;
      sections.forEach((section) => {
        if (scrollPosition >= section.offsetTop) {
          section.classList.add("active");
        }
      });
    });
  },
  watch: {
    currentSection: function (val) {
      console.log("val" + val);
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },

  components: {
    Hero,
    Steps,
    AppWhereToBuy,
    AppStories,
    AppAllStories,
    AppStickers,
    AppPostcard,
    AppPartners,
    AppProducts,
    About,
  },
};
</script>

<style lang="scss">
.home {
  background: #fff;
}
</style>
