<template>
  <div class="postcards">
    <iframe
      id="iframe"
      class="postcards__iframe"
      :src="`${publicPath}constructor/index.html?auth_token=${$store.getters.token}`"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    listener(event) {
      console.log(event.data);
      if (event.data === "kinder.save") {
        this.gtmEvent("", "postcard", "postcard_success");
      }
    },
  },
  mounted() {
    this.$parent.showFooter = true;
    if (!this.$store.getters.user) {
      this.$router.push({ name: "Home" });
    }

    if (window.addEventListener) {
      window.addEventListener("message", this.listener);
    } else {
      // IE8
      window.attachEvent("onmessage", this.listener);
    }
  },
};
</script>

<style lang="scss" scoped>
.postcards {
  min-height: 100vh;
  padding-top: rem(60px);
  padding-bottom: rem(0px);
  background: #fff;
  &__iframe {
    width: 100%;
    height: 950px;
    min-height: 150vh;
    &::-webkit-scrollbar {
      display: none;
      overflow-x: hidden;
    }
  }
  @media (orientation: landscape) {
    padding-top: rem(70px);
    padding-bottom: rem(130px);
    &__iframe {
      width: 100%;
      height: 100%;
      min-height: 100vh;
    }
  }
}
</style>
